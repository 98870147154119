import React from 'react'
import { Images } from "../theme"
import { Link } from "gatsby"

const HomeProjects = () => {
    return (
        <div className="hpjcts">
            <div className="fx fxdc fxjcc fxaic">
                        <h1>Highest Quality Silver Supplement</h1>
            <div className="pd15">
            <div className="cicdv">  
                    <div className="cic">
        <div className="cicimg" style={{backgroundImage: `url(${Images.bot500})`}}></div>
                    </div>
                    <div className="cic">
        <div className="cicimg" style={{backgroundImage: `url(${Images.botsProds})`}}></div>
                    </div>
                    <div className="cic">
        <div className="cicimg" style={{backgroundImage: `url(${Images.bot1000})`}}></div>
                    </div>
            </div>
            </div>
            <h2>Actively Charged | Easily Absorbed</h2>
                <div className="">
                    <div className="pd15">  
                        <p>
                        Greater than 98% bio-active silver ions and nanoclusters. Exponentially more powerful than other brands, even those with 250, 500 and 1000 ppm. Unprecedented particle size as small as 0.8 nm (nanometers), validated by Transmission Electron Microscopy, allows for easy absorption into and excretion from the body.
                        </p>
                <span className="mobtn">
                    <Link to="#">More About Our Silver</Link>
                </span>
                </div>
                </div>

            </div>
          </div>
    )
}

export default HomeProjects
