const Images = {
  limeLogo: require("../images/lime-logo.jpg"),
  whiteLogo: require("../images/white-logo.jpg"),
  osvnPalms: require("../images/7-palms.png"),
  svnPalms: require("../images/seven.palms.png"),
  scan: require("../images/scan.jpg"),
  check: require("../images/svg/check.svg"),
  rswoop: require("../images/svg/rswoop.svg"),
  lswoop: require("../images/svg/lswoop.svg"),
  fbo: require("../images/sm/fbo.png"),
  twto: require("../images/sm/twto.png"),
  yto: require("../images/sm/yto.png"),
  ino: require("../images/sm/ino.png"),
  iconWhite: require("../images/icon-white.jpg"),
  icon: require("../images/icon.png"),
  mhWhite: require("../images/modern-homes-white.jpg"),
  mh: require("../images/modern-homes.png"),
  sreWhite: require("../images/sre-white.jpg"),
  sre: require("../images/sre.png"),
  xxx: require("../images/xxx.png"),
  scan2o: require("../images/scan-2.jpg"),
  sreLogoo: require("../images/sre-logoo.png"),
  scan2: require("../images/scan-2.png"),
  karl: require("../images/karl.png"),
  sreLogo: require("../images/sre-logo.png"),
  SPBrochure: require("../images/seven-palms-brochure.pdf"),
  img1: require("../images/renders/old/1.jpg"),
  img2: require("../images/renders/old/2.jpg"),
  img3: require("../images/renders/old/3.jpg"),
  img4: require("../images/renders/old/4.png"),
  img5: require("../images/renders/old/5.jpg"),
  img6: require("../images/renders/old/6.jpg"),
  img7: require("../images/renders/old/7.jpg"),
  img8: require("../images/renders/old/8.png"),
  boopo: require("../images/renders/old/boopo.png"),
  bots1: require("../images/1bots.png"),  
  bots2: require("../images/2bots.png"),
  botsProds: require("../images/botsProds.png"),
  bot500: require("../images/bot500.png"),
  bot1000: require("../images/bot1000.png"),

  img9: require("../images/renders/old/9.png"),
  img90: require("../images/renders/old/90.png"),
  img10: require("../images/renders/old/10.jpg"),
  img11: require("../images/renders/old/11.jpg"),
  img12: require("../images/renders/old/12.png"),
  img13: require("../images/renders/old/13.jpg"),
  nimg1: require("../images/renders/new/1.jpg"),
  nimg2: require("../images/renders/new/2.jpg"),
  nimg3: require("../images/renders/new/3.jpg"),
  nimg4: require("../images/renders/new/4.jpg"),  
  simg01: require("../images/site/1.jpg"),
  simg02: require("../images/site/2.jpg"),
  simg03: require("../images/site/3.jpg"),
  simg04: require("../images/site/4.jpg"),
  simg05: require("../images/site/5.jpg"),
  simg06: require("../images/site/6.jpg"),
  simg07: require("../images/site/7.jpg"),
  simg08: require("../images/site/8.jpg"),
  simg09: require("../images/site/9.jpg"),
  simg10: require("../images/site/10.jpg"),
  simg11: require("../images/site/11.jpg"),
  simg12: require("../images/site/12.jpg"),
  simg13: require("../images/site/13.jpg"),
  simg14: require("../images/site/14.jpg"),
  simg15: require("../images/site/15.jpg"),
  simg16: require("../images/site/16.jpg"),
  simg17: require("../images/site/17.jpg"),
  simg18: require("../images/site/18.jpg"),
  simg19: require("../images/site/19.jpg"),
  simg20: require("../images/site/20.jpg"),








};

export default Images;
