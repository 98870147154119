import React from 'react'

const Mission = () => {
    return (
        <div className="msn">
            <div className="fx fxdr fxjcc fxaic">
                <div className="w33">
                    <div className="pd15"> 
                    <h1><span role="img" aria-label="Perfectly Safe">💎</span></h1>
                        <h1 style={{marginTop: "1.45rem"}}>Perfectly Safe</h1>
                        <p>Safe for the whole family</p>
                    </div>
                </div>
                <div className="w33">
                    <div className="pd15">
                    <h1><span role="img" aria-label="Vision">🎯</span></h1>
                        <h1 style={{marginTop: "1.45rem"}}>Dietary Supplement</h1>
                        {/* <p>{"To be the first to introduce the best \n off plan deals anyone in Kenya has ever experienced,\n to be the best in what we do."}</p> */}
                        <p>15 ppm . Ultra-fine Dispersion </p>
                    </div>
                </div>
                <div className="w33">
                    <div className="pd15"> 
                    <h1><span role="img" aria-label="Values">🏆</span></h1>
                        <h1 style={{marginTop: "1.45rem"}}>99.999% Pure</h1>
                        <p>No added salts or proteins</p>
                    </div>
                </div>
                 
            </div>
        </div>
    )
}

export default Mission
