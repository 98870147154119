import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Blog = () => {

    const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 10) {
          edges {
            node {
              excerpt
              fields {
                slug 
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                pic
                image {
                  childImageSharp {
                    fluid(maxWidth: 510, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                brief
              }
            }
          }
        }
      }
  `)

        return (
        <div className="hblog">
        <div className="fx fxdc fxaic hblogdv">
                    <h1 style={{color: `#FFF`}}>BLOG ARTICLES</h1>
                <div className="w100 pd15"> 
                    <div className="cf">  
{data.allMarkdownRemark.edges.map(({ node }) => {
    const title = node.frontmatter.title || node.fields.slug
    return (
      <Link className="hbdv w45 lft" to={node.fields.slug} key={node.fields.slug}
        style={{
            background: `#1D4354`,
            backgroundImage: `url("../../content/blog${node.fields.slug}${node.frontmatter.pic}.jpeg")`,
          }}>
                    <div className="w100">
                    <Img fluid={node.frontmatter.image.childImageSharp.fluid}/>
                    </div>
                    <div className="w50">
                        <h3>{title}</h3>
                        <p>{node.frontmatter.description}</p> 
                        <span className="rmlink">Read More</span>
                    </div>
      </Link>
    )
  })}
                </div>
            </div>

        </div>
      </div>
      )
   
}

export default Blog

