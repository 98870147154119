import React from 'react'
import { Images } from "../theme"

const About = () => {
    return (
        <div className="about">
            <div className="fx fxdr fxjcc fxaic">
                <div className="w50">
                    <div className="pd15">  
                        <h1>ABOUT US</h1>
                        <p>
                        We are here to offer serenity, style and comfort; the pillars of good living. Our vision is to dot Kenya with the Scandinavian feel of homes. The concept of our designs has been carved out to an alluring finish that growing families will find ideal.
                        </p>
                </div>
                </div>
                <div className="w50">
                    <div className="pd15">  
                        <img src={Images.scan2} alt="Scan Real Estate Ltd"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
