import React from 'react'
import { Images } from "../theme"
import { Link } from "gatsby"


const Footer = () => {
    return (
<footer id="ft" style={{backgroundImage: `url(${Images.xxx})`}}>
  <div className="ftcont cf">
        <div className="w50 rit">
            <div className="scnet">
                <ul>
                    <li>
                    <a href="https://www.facebook.com/#" target="_blank" rel="noopener noreferrer">
                    <img src={Images.fbo} alt="facebook" />
                    </a>
                    </li>
                    <li>
                    <a href="https://twitter.com/#" target="_blank" rel="noopener noreferrer">
                    <img src={Images.twto} alt="twitter" />
                    </a>
                    </li>
                    <li>
                    <a href="https://www.youtube.com/channel/#" target="_blank" rel="noopener noreferrer">
                    <img src={Images.yto} alt="youtube" />
                    </a>
                    </li>
                    <li>
                    <a href="https://www.instagram.com/#" target="_blank" rel="noopener noreferrer">
                    <img src={Images.ino} alt="instagram" />
                    </a>
                    </li>
                </ul>
            </div> 
            <div className="smcont">
            <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="#">About Us</Link></li>
                <li><Link to="#">Products</Link></li>
                <li><Link to="/contact-us">Contact Us</Link></li>     
            </ul>
            </div>
        </div>
        <div className="coinfo w50 lft">
            <div><span role="img" aria-label="Phone">☎️ </span><span><a href="tel:+116129165275"> +1 161 2916 5275</a></span></div>
            <div><span role="img" aria-label="Email">✉️ </span><span><a href="mailto:info@naturalsilversolution.com"> info@naturalsilversolution.com</a></span></div>
            <div><span role="img" aria-label="Copyright">©️  {new Date().getFullYear()}, Natural Silver Solutions Limited</span></div>
            <div>Designed &amp; Built by<a target="_blank" rel="noopener noreferrer" href="https://bryanczar.com"> Czerian</a></div>
        </div>
  </div>
</footer>
    )
}

export default Footer
