import React from 'react'
import { Images } from "../theme"
import { Link } from "gatsby"

const HomeAbout = () => {
    return (
        <div className="about">
            <div className="fx fxdr fxjcc fxaic">
                <div className="w50">
                    <div className="pd15">  
                        <h1>ABOUT US</h1>
                        <p>
                        Purity. Consistency. Effectiveness. These are what you want and your patients expect in the products you recommend. Our laboratories are equipped with some of the world’s most advanced analytic equipment, and our team of scientists include in-house experts specialized in nanoparticle science.
                        </p>
                        <span className="mobtn"><Link to="#">More About Us</Link></span>
                </div>
                </div>
                <div className="w50">
                    <div className="pd15">  
                        <img src={Images.karl} alt="Natural Silver Solutions"/>
                    </div>
                </div>
            </div>
        </div>      
    )
}

export default HomeAbout

