import React from 'react'
import { Images } from "../theme"
import { Link } from "gatsby"

const SvnPalms = () => {
    return (
        <div className="svn">
            <div className="fx fxdr fxjcc fxaic">
                <div className="w50">
                    <div className="pd15">  
                    <Link to="/our-projects/seven-palms"><img src={Images.svnPalms} alt="Scan Real Estate Ltd"/></Link>
                </div>
                </div>
                <div className="w50">
                    <div className="pd15">  
                    <h1>Seven Palms - Katani RD</h1>
                        <p>
                        This is our first-born of many more outstanding developments to come; inspired by Scandinavian architecture.  
                        Seven palms is a gated community consisting of 16 maisonettes that are well spaced out ensuring privacy yet not too detached thus still embracing the essence of community.
                        </p>
                        <span className="mobtn"><Link to="/our-projects/seven-palms">More About Seven Palms</Link></span>
                    </div>
                </div>
            </div>
        </div>      
    )
}

export default SvnPalms

