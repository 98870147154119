import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import { Images } from "../theme"

const HomeBlog = () => {

    const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
          edges {
            node {
              excerpt
              fields {
                slug 
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                pic
                image {
                  childImageSharp {
                    fluid(maxWidth: 510, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                brief
              }
            }
          }
        }
      }
  `)

        return (
        <div className="hblog">
        <div className="" style={{backgroundColor: `rgba(0, 0, 0, 0.00)`}}>
        <div className="fx fxdc fxaic">
                    <h1>Products Shop</h1>
                <div className="w100 pd15"> 
                    <div className="cf">  
{data.allMarkdownRemark.edges.map(({ node }) => {
    const title = node.frontmatter.title || node.fields.slug
    return (
      // <Link className="hbdv w45 lft" to={node.fields.slug} key={node.fields.slug}
      <Link className="hbdv w45 lft" to='#' key={node.frontmatter.title}
        // style={{
        //     backgroundImage: `url("../../content/nss/${node.frontmatter.pic}.png")`,
        //   }}
        >
                    <div className="w100">
                    <Img fluid={node.frontmatter.image.childImageSharp.fluid}/>
                    </div>
                    <div className="w50">
                        <h3>{title}</h3>
                        <p>{node.frontmatter.description}</p> 
                        <span className="rmlink">View More</span>
                    </div>
      </Link>
    )
  })}
                </div>
                <span className="mobtn"><Link to="#">More Products</Link></span>
            </div>

        </div>
      </div>
      </div>
      )
   
}

export default HomeBlog

