import React from "react"
import { Images } from "../theme"
import { Link } from "gatsby"


const Header = () => {
  function mnadd() {
    console.log ('adddddd');
    document.body.classList.add("menu-open")
  };
  function mnrmv() {
    console.log ('removeeeee');
    document.body.classList.remove("menu-open");
  }
  // const mntgl = () => {
  //   console.log ('sexi sexi');
  //   document.body.classList.toggle("menu-open");
  // }

  return (
  <>
  <div className="hd">
    <div className="hddv">

<header>
	<div className="cf">
    <div className="left">
        <div className="hdlg">
          <Link to="/"><img src={Images.sreLogo} alt="Scan Real Estate" /></Link>
        </div>
		</div>
    <div className="right">
      <div className="btn-menu" onClick={e => mnadd()} >
      {/* onMouseEnter={e => mntgl()} */}
          <i className="fas fa-bars"></i><span> Menu</span>
			</div>
		</div>
	</div>
</header>

  </div>
</div>

<div id="mobile_nav_wrapper">
	<div className="mobile-nav-content">
		<div className="header">
			<span className="menu-close icon-close" onClick={e => mnrmv()} >
        <i className="far fa-times-circle"></i></span>
			<h2 className="desktop">Menu</h2>
		</div>
      <ul id="main_menu" className="mobile-nav active">
          <li><Link to="/" className="active" onClick={e => mnrmv()} >Home</Link></li>
          <li><Link to="#" className="active" onClick={e => mnrmv()} >About Us</Link></li>
          <li>
            <Link to="#" className="active" onClick={e => mnrmv()} >Products</Link>
            {/* <ul><li><Link to="/our-projects/seven-palms" className="active" onClick={e => mnrmv()} >Seven Palms</Link></li></ul> */}
          </li>
          {/* <li><Link to="/blog" className="active" onClick={e => mnrmv()} >Blog</Link></li> */}
          <li><Link to="/contact-us" className="active" onClick={e => mnrmv()} >Contact Us</Link></li>     
      </ul>
	</div>
</div> 
  
</>
)
}

export default Header
