import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Header, Footer } from "."
import "./layout.css"
import {Helmet} from "react-helmet";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SitePageTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,800&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css?family=Titillium+Web:300,400,700&display=swap" rel="stylesheet"></link> 
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css" rel="stylesheet"></link>
        <script>{`
            // window.addEventListener('DOMContentLoaded', () => {
            //   bd = document.querySelector('body');
            //   bd.addEventListener('click', function (e) {
            //         document.body.classList.remove("menu-open"); 
            //             console.log ('pujaa');
            //       });  
            // })
        `}
        </script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>   
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
