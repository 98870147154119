import React, { Component } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

class Contact extends Component {
    constructor(props) {
    super(props);
        this.state = {
        name: "",
        email: "",
        phone: "",
        msg: "",
        recaptchare: "",
        olla: {
            hello: [
            { key: 1, say: "Good To Talk - Say Hi!" },
            ],
            },
        sending: false,
        subRes: '',
        };
    }


   
    componentDidMount() {

    }

    contactAction = (helloMsg) => {
        axios
        .post('https://naturalsilversolution.com/sent/processer.php', helloMsg) 
        .then(res => {       
          this.setState({ subRes: res.data });
          console.log(res)
          this.setState({ sending: false });
        })
        .catch(err => {
          this.setState({ subRes: err.response });
          console.log(err);
          this.setState({ sending: false });
        });
    }

    conSubmit = e => {
            e.preventDefault();
            const helloMsg = {
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                msg: this.state.msg,
                recaptchare: this.state.recaptchare,
            };
            this.contactAction(helloMsg);
            this.setState({ sending: true });
    }

    ifChange = e => {
            this.setState({ [e.target.name]: e.target.value });
    };

    reCap = e => {
        this.setState({ recaptchare: e });
        console.log(e, "<<== Sexy Token")
    };

    render() {
    const { olla, sending, subRes } = this.state
            return (
            <div className="condiv fx fxdc fxjcc fxaic tac">
                <h1>
                {olla ? (`${olla.hello[0].say}`):('Good To Talk/Say Hi')}
                </h1>

                <form onSubmit={this.conSubmit} className="conForm">
                    <input
                        type="text"
                        name="name"
                        autoComplete="off"
                        placeholder="Your Name"
                        onChange={this.ifChange}
                    />
                    <input
                        type="text"
                        name="email"
                        autoComplete="off"
                        placeholder="E-mail Address"
                        onChange={this.ifChange}
                    />
                    <input
                        type="text"
                        name="phone"
                        autoComplete="off"
                        placeholder="Phone Number"
                        onChange={this.ifChange}
                    />

                    <textarea
                    name="msg"
                    placeholder="Say Something/Your Message"
                    onChange={this.ifChange}
                    />

                    <div className="recap">
                    <ReCAPTCHA
                        sitekey="6Lc__aoaAAAAAJhru1GnTVxn1R4f0L1dyMBlSLFf"
                        onChange={this.reCap}
                    />
                    </div>
                <small><em>{sending ? ('Sending.....'):(subRes)}</em></small>
                    <input type="submit" value="Send Message" />
                </form>                   
            </div>
        );
    }
}


export default Contact