import React from "react"
import { Images } from "../theme"

const Cloud = () => (
  <div className="cldiv" style={{backgroundImage: `url(${Images.img9})`}}>
  <div className="" style={{backgroundColor: `rgba(0, 0, 0, 0.48)`, backgroundImage: `url(${Images.xxx})`}}> 
  {/* style={{backgroundImage: `url(${Images.xxx})`}} */}
        <div className="fx fxdr fxjcc fxaic">
            <div className="w50">
              <div className="cicl">
                  <div className="cicimg" style={{backgroundImage: `url(${Images.bots1})`}}></div>
              </div>
            </div>
            <div className="w50">
              <h1>Natural Silver Solutions</h1>
              <h2>Professional Bio-Active colloidal silver supplements.</h2>

            </div>
        </div>
      </div>
  </div>
)

export default Cloud
